import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { PressReleaseAttachmentSettings } from 'app/settings.class';

@Injectable()
export class ValidatorConversionService {

    public getValidatorByString(rule: string): any {
        const validator = rule.split(':');
        switch (validator[0]) {
            case 'required':
                return Validators.required;
            case 'email':
                return Validators.email;
            default:
                return false;
        }
    }
    fileIdValidator(control: AbstractControl): ValidationErrors | null {
      const val = control.value;
      if (!val) { // if value is blank, consider it valid
        return null;
      }
      const intHyphenIntReg = /^\d+-\d+$/.test(val);
      return intHyphenIntReg ? null : { ref_file_error: true };
    }

    validateFile(file: File, maxSize: number, validExtensions: string[]): string[] {
        let errors = [];
        if (file.size / 1024 > maxSize) {
            errors.push(PressReleaseAttachmentSettings.INVALID_SIZE_ERROR_KEY);
        }
        if (!validExtensions.includes(file.name.split('.').pop())) {
            errors.push(PressReleaseAttachmentSettings.INVALID_EXTENSION_ERROR_KEY);
        }
        return errors;
    }

    getFileMaxSizeFromRules(rules: string[]): number {
        const maxSizeRule = rules.find((rule) => rule.includes('max'));
        return maxSizeRule ? parseInt(maxSizeRule.split(':')[1]) : PressReleaseAttachmentSettings.MAX_FILE_SIZE;
    }

    getFileValidExtensionsFromRules(rules: string[]): string[] {
        const validMimeRule = rules.find((rule) => rule.includes('mimes'));
        return validMimeRule ? validMimeRule.split(':')[1].split(',') : PressReleaseAttachmentSettings.VALID_EXTENSIONS;
    }

    dateRangeValidator(canBeEqual: boolean = false) {
        return (control: AbstractControl): ValidationErrors | null => {
            const from_date = control.get('from_date').value;
            const to_date = control.get('to_date').value;
            if (from_date && to_date) {
                if(canBeEqual) {
                    return from_date <= to_date ? null : { date_range_error: true };
                } else {
                    return from_date < to_date ? null : { date_range_error: true };
                }
            }
            return null;
        };
    }
}
