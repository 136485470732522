import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MLSendStatus } from 'app/company-reports/models/MLSendStatus';
import { CompanyReportsService } from '../../services/company-reports.service';
import * as moment from 'moment';
import { UntypedFormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { EntitiesService, EntityDescription } from '../../../entities/services/entities.service';
import { DateConverterService } from 'app/shared/services/date-converter.service';
interface DateObject {
  from_date: string;
}
@Component({
  selector: 'con-report-dates',
  templateUrl: './report-dates.component.html',
  styleUrls: ['./report-dates.component.scss']
})

export class ReportDatesComponent implements OnInit, OnDestroy {
  @Input() entity: any = {};

  fromDateControl = new UntypedFormControl('');
  fromDateField = {
    key: 'from_date'
  }
  currentFromDateValue = '';

  toDateControl = new UntypedFormControl('');
  toDateField = {
    key: 'from_date'
  }
  currentToDateValue = '';

  showDateMismatchError = false;

  prefillData = {
    calendar_event_type: null,
    calendar_event_type_id: null,
    owner_id: null,
    owner_type: 'Company',
    company_id: null,
  };
  
  public changes$: Subject<any> = new Subject<any>();
  public MLSendStatus = MLSendStatus;
  public eventDateRange: any;
  public now: string = this.dateConverter.toEntityString(moment());
  private tomorrow: string = this.dateConverter.toEntityString(moment().add(1, 'days').startOf('day'));
  showCreateForm = true;
  createFormDataReady = false;
  calendarEventEntity: any = {};
  calendarTypeSub$: Subscription;
  entityDescriptionSub$: Subscription;
  filterButtonEnabled = false;
  formRelationValues = {}
  constructor(public companyService: CompanyReportsService, private toastr: ToastrService, private entityService: EntitiesService, private dateConverter: DateConverterService) {
  }

  ngOnInit(): void {

    this.fromDateControl.setValue(this.getEightMonthRange().fromDate);
    this.toDateControl.setValue(this.getEightMonthRange().toDate);
    this.currentFromDateValue = this.getEightMonthRange().fromDate;
    this.currentToDateValue = this.getEightMonthRange().toDate;
    this.calendarTypeSub$ = this.companyService.getCalendarEventTypes().subscribe((response: any) => {
      let earningsObject;
      for (let eventType of environment.calendar_event_types) {
        earningsObject = response.data.find((item) => item.name === eventType);
        if (earningsObject) break;
      }
      if (earningsObject) {
        this.formRelationValues = { calendarEventType: earningsObject, owner: this.entity?.company };
        this.prefillData.calendar_event_type = earningsObject;
        this.prefillData.calendar_event_type_id = earningsObject.id;
        this.prefillData.owner_id = this.entity.company_id;
        this.prefillData.company_id = this.entity.company_id;
        this.eventDateRange = this.getDateRange();
        this.entityDescriptionSub$ = this.entityService.getAllEntityDescriptions().subscribe((data: EntityDescription[]) => {
          const calendarEventMeta = data.find((item) => item?.name === 'CalendarEvent');
          if (calendarEventMeta) {
            const sourceTypeField = calendarEventMeta?.data?.fields.find(field => field?.key === 'source_type');
            if (sourceTypeField) {
              let containsUser = sourceTypeField?.values.some(obj => obj?.value === environment.calendar_event_default_source_type);
              if (containsUser) {
                this.calendarEventEntity = { ...this.prefillData };
                this.calendarEventEntity.from_date = this.tomorrow;
                this.createFormDataReady = true;
              } else {
                this.createFormDataReady = true;
              }
            } else {
              this.createFormDataReady = true;
            }
          } else {
            this.createFormDataReady = true;
          }
        });
      } else {
        this.toastr.error('Earnings event type not found')
      }
    }, (error)=>{
      this.toastr.error('Error fetching event types')
    })

  }

  updateFilterCheck() {
    if(this.currentFromDateValue === this.fromDateControl.value && this.currentToDateValue === this.toDateControl.value) {
      return;
    }
    this.showDateMismatchError = false;
    if(this.currentFromDateValue !== this.fromDateControl.value){
      this.currentFromDateValue = this.fromDateControl.value;
    }

    if(this.currentToDateValue !== this.toDateControl.value){
      this.currentToDateValue = this.toDateControl.value;
    }

    if(this.currentFromDateValue && this.currentToDateValue ) {
      // validate dates
      const fromDate = moment(this.currentFromDateValue);
      const toDate = moment(this.currentToDateValue);
      if(toDate.diff(fromDate) < 0) {
        // to date is less than from date
        this.showDateMismatchError = true;
        return;
      }
    }

    const filterObject = {
      fromDate: this.currentFromDateValue,
      toDate: this.currentToDateValue
    }
    if(filterObject.fromDate && filterObject.toDate) {
      this.eventDateRange = { 'from_date': `f:geq:${filterObject.fromDate}&f:leq:${filterObject.toDate}`, 'owner_id': `f:eq:${this.entity.company_id}`,'calendar_event_type_id': this.prefillData.calendar_event_type_id, 'owner_type': this.prefillData.owner_type };
      // set cd.detectChanges() is not sending latest request so setTimeout is used
      setTimeout(()=> {
        this.changes$.next(true);
        this.filterButtonEnabled = false;
      }, 0);
    }
  }
  updateFilterButtonStatus() {
    this.filterButtonEnabled = true;
    this.showDateMismatchError = false;
  }
  getDisplayTitle() {
    let displayName = this.entity.company.name;
    if (this.entity) {
      displayName +=
        ' / ' +
        this.entity.report_type +
        ', ' +
        moment(this.entity.report_date).format('yyyy-MM-DD');
    }
    return displayName;
  }

  getEightMonthRange() {
    const currDate = moment();
    const monthAheadDate = moment(currDate).add(8, 'M');
    const monthBehindDate = moment(currDate).subtract(8, 'M');

    const fromDate = monthBehindDate.format('YYYY-MM-DD');
    const toDate = monthAheadDate.format('YYYY-MM-DD');

    return {
      fromDate,
      toDate
    };
  }
  getDateRange() : any {
    let fromDate = this.getEightMonthRange().fromDate;
    let toDate = this.getEightMonthRange().toDate;
    return {
      'from_date': `f:geq:${fromDate}&f:leq:${toDate}`,
      'owner_id': `f:eq:${this.entity.company_id}`,
      'calendar_event_type_id': this.prefillData.calendar_event_type_id,
      'owner_type': this.prefillData.owner_type
    };
  }

  updateListing() {
    this.changes$.next(true);
  }
  expandCreateForm() {
    this.showCreateForm = ! this.showCreateForm;
  }
  getMutations(event) {
    return (!event ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']);
  }
  ngOnDestroy(): void {
    this.entityDescriptionSub$.unsubscribe();
    this.calendarTypeSub$.unsubscribe();
  }
}
