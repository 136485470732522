<div class='container-fluid mb-3'>
  <div class='row'>
    <div class='col-12'>
      <div class='page-title mb-2 pb-3 pt-3'>
        <h3 class='mb-0'>Active Consensus Snapshot</h3>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isLoading; else elseContentSection">
    <con-loader></con-loader>
  </ng-container>
  <ng-template #elseContentSection>
    <div class='row'>
      <div class='col-12'>
        <div class="d-flex justify-content-start sticky-header non-sticky py-2">
          <h3 class="mb-0">
            <a routerLink="../../../../entity/company/{{company?.id}}"><span class="badge badge-dark">{{company?.name}}</span></a>
            <span *ngIf="company?.website"><a [href]=company?.website class="link-fontsize" target="_blank">{{getStripedWebsiteString(company?.website)}}</a></span>
          </h3>
          <div class="chip chip-inverse ml-auto">
            <con-avatar [userId]="company?.manager?.id" [size]="32"></con-avatar>
            {{company?.manager?.first_name}} {{company?.manager?.last_name}}
          </div>
        </div>
      </div>
    </div>
    <con-no-data *ngIf="currentSurveyDetails?.locked" count='0' class='approved-survey consensus-message'></con-no-data>
    <div class="alert ml-status-failed" *ngIf="showData && consensusData?.survey?.taxonomy_id === null">
      <con-icon-by-name iconName="warning"></con-icon-by-name> No taxonomy attached!
    </div>
    <div *ngIf="showData && consensusData?.survey?.taxonomy_id !== null">
      <div [ngClass]="{ 'is-locked': currentSurveyDetails?.locked}">
        <div class="card mb-2">
          <div class="card-header">
            <div class="d-flex justify-content-start align-items-center">
              <con-icon-by-name iconName="paperclip" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
              Active Consensus snapshot for {{ consensusData?.survey?.date }} survey <span class="ml-2" *ngIf="consensusData?.unit?.name"> ({{consensusData?.unit?.name}} <span *ngIf="consensusData?.quantity"> / </span>{{consensusData?.quantity?.name}})</span>
              <div class="card-header-toggle-individual ml-auto">
                <button class="btn btn-success  mr-2" type="button" (click)="downloadTemplate()" [disabled]="currentSurveyDetails?.locked">
                  <con-icon-by-name iconName="file-o text-white" class="mr-1"></con-icon-by-name>
                  Download template
                </button>
                <button class="btn btn-primary  mr-2" type="button" (click)="openImportModal()" [disabled]="currentSurveyDetails?.locked">
                  <con-icon-by-name iconName="cloud-upload text-white" class="mr-1"></con-icon-by-name>
                  Import
                </button>
                <button class="btn btn-primary mr-2" type="button" [disabled]="currentSurveyDetails?.locked || !isAPISyncEnabled" *ngIf="latestSurveyId === consensusData?.survey?.id" (click)="fetchLatestConsensusManually()" [ngClass]="{ 'pointer-events-none': fetchConsensusLoading }">
                  <ng-container *ngIf="fetchConsensusLoading">
                    <div class="spinner-border spinner-border-sm mr-1"></div>
                  </ng-container>
                  Fetch latest consensus
                </button>
                <con-icon-by-name iconName="angle-right"></con-icon-by-name>
              </div>
            </div>
          </div>
          <div>
            <div class="p-4 col-12 table-responsive pb-0-imp">
              <div class="full-width-custom">
                <strong>Coverage</strong><br />
                <p *ngIf="consensusData?.coverage.length && showData">
                  Analysts from
                  <span *ngFor="let coverage of consensusData?.coverage; let isLast=last">
                    <a routerLink="../../../../entity/firm/{{coverage?.firm_id}}" target="_blank">
                      {{coverage?.firm_name}}<ng-container *ngIf="!isLast">,</ng-container>
                    </a>

                  </span>
                  contributed to the poll.
                </p>
              </div>
              <div class="p-4 col-12 mb-15 message-bg" *ngIf="!consensusData?.coverage.length && showData">
                No coverage data available.
              </div>
            </div>
            <div class="p-4 col-12 pt-0-imp pb-0-imp" *ngIf="showData">
              <form [formGroup]="recommendationsForm" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col-6">
                    <div class="full-width-custom">
                      <strong>Target Price <span *ngIf="consensusData?.survey?.currency">({{consensusData?.survey?.currency?.name}})</span></strong>
                    </div>
                    <div class="consensus-status-bg">
                      <table class="consensus-table table bg-white">
                        <thead class="thead-light">
                          <tr>
                            <th>Mean</th>
                            <th>Median</th>
                            <th>High</th>
                            <th>Low</th>
                            <th>No</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr formGroupName="targetPrice">
                            <td>
                              <input formControlName="id" hidden>
                              <input formControlName="mean" [placeholder]="currentSurveyDetails?.locked ? '-' : ''"><br/>
                              <small *ngIf="recommendationsForm.get('targetPrice').get('mean').invalid">Invalid value</small>
                            </td>
                            <td>
                              <input formControlName="median" [placeholder]="currentSurveyDetails?.locked ? '-' : ''"><br/>
                              <small *ngIf="recommendationsForm.get('targetPrice').get('median').invalid">Invalid value</small>
                            </td>
                            <td>
                              <input formControlName="high" [placeholder]="currentSurveyDetails?.locked ? '-' : ''"> <br/>
                              <small *ngIf="recommendationsForm.get('targetPrice').get('high').invalid">Invalid value</small>
                            </td>
                            <td>
                              <input formControlName="low" [placeholder]="currentSurveyDetails?.locked ? '-' : ''"><br/>
                              <small *ngIf="recommendationsForm.get('targetPrice').get('low').invalid">Invalid value</small>
                            </td>
                            <td>
                              <input formControlName="amount" [placeholder]="currentSurveyDetails?.locked ? '-' : ''"> <br/>
                              <small *ngIf="recommendationsForm.get('targetPrice').get('amount').invalid">Invalid value</small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <button *ngIf="!currentSurveyDetails?.locked" type="submit" class="btn btn-success mt-15">Save</button>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="full-width-custom">
                      <strong>Recommendations / Outlook</strong>
                    </div>
                    <div class="consensus-status-bg">
                      <table class="consensus-table table bg-white">
                        <thead class="thead-light">
                          <tr>
                            <th>Buy</th>
                            <th>Hold</th>
                            <th>Sell</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr formGroupName="outlook">
                            <td>
                              <input formControlName="id" hidden>
                              <input formControlName="buy" [placeholder]="currentSurveyDetails?.locked ? '-' : ''"><br/>
                              <small *ngIf="recommendationsForm.get('outlook').get('buy').invalid">Invalid value</small>
                            </td>
                            <td>
                              <input formControlName="hold" [placeholder]="currentSurveyDetails?.locked ? '-' : ''"><br/>
                              <small *ngIf="recommendationsForm.get('outlook').get('hold').invalid">Invalid value</small>
                            </td>
                            <td>
                              <input formControlName="sell" [placeholder]="currentSurveyDetails?.locked ? '-' : ''"><br/>
                              <small *ngIf="recommendationsForm.get('outlook').get('sell').invalid">Invalid value</small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <button *ngIf="!currentSurveyDetails?.locked" type="submit" class="btn btn-success mt-15">Save</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="p-4 col-12 pt-0-imp" *ngIf="showData && consensusData?.estimates === null">
              <div class="p-4 col-12 message-bg mt-15">
                No consensus period data available.
              </div>
            </div>
            <div class="p-4 col-12" *ngIf="showData && consensusData.estimates">

              <!-- loop start -->
              <div *ngFor="let data of consensusData.estimates">
                <div class="card mb-2">
                  <div class="card-header" (click)="data.hide = !data.hide">
                    <div class="d-flex justify-content-start align-items-center">
                      <con-icon-by-name iconName="database" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
                      {{data.estimate_period}}
                      <div class="card-header-toggle-individual ml-auto">
                        <con-icon-by-name [iconName]="data.hide ? 'angle-right' : 'angle-down'"></con-icon-by-name>
                      </div>
                    </div>
                  </div>
                  <div class="p-4 col-12" *ngIf="!data?.hide">
                    <!-- show consensus view -->
                    <con-consenus-snapshot [modules]="data.modules" [isSurveyLocked]="currentSurveyDetails?.locked"></con-consenus-snapshot>
                  </div>
                </div>
              </div>
              <!-- loop end -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
