import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { ProfileService } from '../../auth/services/profile.service';
import { HttpClient } from '@angular/common/http';

import { ApiSettings, CalendarEventSettings, CalendarSettings } from '../../settings.class';
import * as moment from 'moment';
import { DateConverterService } from '../../shared/services/date-converter.service';
import { CountService } from '../services/count.service';
import { EntitiesService } from 'app/entities/services/entities.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'con-missing-events-editor',
    templateUrl: './missing-events-editor.component.html'
})

export class MissingEventsEditorComponent implements OnInit, OnDestroy {

    private componentDestroyed$: Subject<boolean> = new Subject();

    @Input() company: any;
    @Input() ownerType: string;
    @Output() afterFixed: EventEmitter<any> = new EventEmitter<any>();
    @Output() afterUpdate: EventEmitter<any> = new EventEmitter<any>();

    public loading: boolean;
    public now: string = this.dateConverter.toEntityString(moment());
    public tomorrow: string = this.dateConverter.toEntityString(moment().add(1, 'days').startOf('day'));
    public showNewEventFlag = false;
    public commentLangDefault = CalendarEventSettings.CALENDAR_EVENT_COMMENT_DEFAULT_LANG;
    public newEventDirectProfileLangs: any = {};
    constructor(public profile: ProfileService,
                private http: HttpClient,
                private dateConverter: DateConverterService,
                private enityService: EntitiesService
            ) {}

    ngOnInit () {
        this.company.missing_event_types.forEach(event => {
            event.expanded = true;
        });
        this.company.newEvents = [];
    }

    ngOnDestroy() {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }

    public eventCreated(event: any) {
        event.editingCommentsDict = {};
        const id = event.calendar_event_type.id;
        const indx = this.company.missing_event_types.findIndex(t => t.id === id);
        this.company.missing_event_types[indx].missing_event = event;
        let allEventsCreated = true;
        this.company.missing_event_types.forEach(missing => {
            if (!missing.missing_event || !missing.missing_event.id) {
                allEventsCreated = false;
            }
        });
        if (allEventsCreated) {
            this.http.put(ApiSettings.BASE_URL +
                            this.getOwnerTypeUri() +
                            this.company.id + '/' +
                            CalendarSettings.MISSING_FIXED_ENDPOINT, {})
                     .subscribe();
        }
       this.direktProfileChanged(event?.direkt_profile, event?.id);
    }
    newEventChanged(event: any, newEvent) {

       newEvent.currentEditingEvent = event;
       newEvent.currentEditingEvent.editingCommentsDict = {};
       this.direktProfileChanged(event?.direkt_profile, event?.id);
    }
    addNewEvent() {
        if (this.company.newEvents.filter( event => event.currentEditingEvent === null).length > 0) {
            return;
        }
        this.company.newEvents.unshift (
            {
                currentEditingEvent: null,
                expanded: true
            }
        )
        this.showNewEventFlag = !this.showNewEventFlag ;
    }
    public toggleType(event?) {
        event.expanded = ! event.expanded;
    }
    public toggleEdit(event, element) {
        element.stopPropagation();
        event.expanded = true;
    }
    getMutations(event) {
        const mutation = event.expanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg'];
        return mutation;
    }
    removeEvent(index: number) {
        this.company.newEvents.splice(index, 1);
    }
    removeMissingEvent(index: number) {
        this.company.missing_event_types.splice(index, 1);
    }
    deleteEvent(index: number) {
        this.company.missing_event_types.splice(index, 1);
    }
    getOwnerTypeUri(): string {
        const url = this.ownerType === 'Institution' ? '/institution/' : '/company/';
        return url;
    }
    goToEvents() {
        const url = 'calendar' + this.getOwnerTypeUri() + this.company.id;
        return url;
    }
    setCommentFlag(flag, event) {
        event.expanded = flag;
    }
    toggleEditOfComment(comment, currentEditingEvent) {
        if (!(comment.id in currentEditingEvent.editingCommentsDict)) {
            currentEditingEvent.editingCommentsDict[comment.id] = false;
        }
        currentEditingEvent.editingCommentsDict[comment.id] = !currentEditingEvent.editingCommentsDict[comment.id];
    }
    isEditingComment(comment, currentEditingEvent?) {
        if (currentEditingEvent) {
            return currentEditingEvent.editingCommentsDict[comment.id];
        } else {
            return false;
        }
    }
    commentAdded(comment, currentEditingEvent, reloadComments = false) {
        if (!reloadComments) {
            currentEditingEvent.comments.push(comment);
        } else {
            currentEditingEvent.isLoadingComments = true;
            this.enityService.getEntityById('CalendarEvent', currentEditingEvent?.id).pipe(takeUntil(this.componentDestroyed$)).subscribe( (response: any) => {
                currentEditingEvent.comments = response.comments;
                currentEditingEvent.isLoadingComments = false;
            }, () => {
                currentEditingEvent.comments.push(comment);
                currentEditingEvent.isLoadingComments = false;
            });
        }
    }
    commentUpdated(comment, currentEditingEvent) {
        const index = currentEditingEvent.comments.findIndex(c => c.id === comment.id);
        if (index > -1) {
          currentEditingEvent.comments[index] = comment;
        }
        this.toggleEditOfComment(comment, currentEditingEvent);
    }
    commentDeleted(comment, currentEditingEvent) {
        const index = currentEditingEvent.comments.findIndex(c => c.id === comment.id);
        if (index > -1) {
            currentEditingEvent.comments.splice(index, 1);
        }
        this.toggleEditOfComment(comment, currentEditingEvent);
    }

    direktProfileChanged(event: any, eventId:number) {
        if(!event)
            this.newEventDirectProfileLangs[eventId] = { languageName: null};
        else {
            this.newEventDirectProfileLangs[eventId] = { languageName: event?.language?.name || 'Swedish'};
        }
    }

    newEventDeleted() {
        this.company.newEvents = [];
    }

    afterDeletingEvent(event: any) {
        event.missing_event = null;
    }
}
