<div class="table-responsive">
    <table class="table table-hover">
        <thead class="thead-light">
            <tr>
                <th *ngIf="isSelectable"></th>
                <th>
                    Id
                    <i class="fa fa-sort-asc fa-lg" [ngClass]="{'active': orderField === 'id' && orderAsc }" (click)="orderBy('id', true)"></i><i class="fa fa-sort-desc fa-lg" [ngClass]="{'active': orderField === 'id' && !orderAsc }" (click)="orderBy('id', false)"></i>
                </th>
                <th *ngFor="let field of entityDescription?.getFieldsForTable(timestamps)">
                    {{ field.label }}
                    <span *ngIf="fieldCanBeOrdered(field)"> <i class="fa fa-sort-asc fa-lg" [ngClass]="{'active': orderField === field.key && orderAsc }" (click)="orderBy(field.key, true)"></i><i class="fa fa-sort-desc fa-lg" [ngClass]="{'active': orderField === field.key && !orderAsc }" (click)="orderBy(field.key, false)"></i></span>
                </th>
              <th *ngIf="entityDescription?.name === 'CompanyReportFile'"></th>
              <th *ngIf="actions.length"> </th>
            </tr>
            <tr>
                <td *ngIf="isSelectable" class="text-center select-all">
                    <input type="checkbox" [(ngModel)]="allSelected" (change)="selectAll()">
                </td>
                <td class="align-middle">
                    <div class="btn-group btn-group-md" role="group">
                        <button class="btn btn-dark" ngbTooltip="Refresh Table" container="body" (click)="refresh()">
                            <con-icon-by-name iconName="refresh" [mutations]="['fw']">
                            </con-icon-by-name>
                        </button>
                        <button class="btn btn-info" [ngbTooltip]="'Showing ' + response?.data.length + ' of ' + response?.total" container="body">
                            <con-icon-by-name iconName="info" [mutations]="['fw']">
                            </con-icon-by-name>
                        </button>
                    </div>
                </td>

                <td *ngFor="let field of entityDescription?.getFieldsForTable(timestamps)">
                  <ng-container *ngIf="showSearchFields">
                    <con-entity-search-by-field
                        [field]="field"
                        [control]="searchForm.controls[field.key]">
                    </con-entity-search-by-field>
                  </ng-container>
                </td>
              <td  *ngIf="entityDescription?.name === 'CompanyReportFile'">
                &nbsp;
              </td>
                <td *ngIf="actions.length">

                </td>
            </tr>
        </thead>
        <tbody *ngIf="!loading">
            <tr *ngFor="let entity of response?.data">
                <td *ngIf="isSelectable" class="text-center">
                    <input type="checkbox" [(ngModel)]="entity.selected" (change)="selectRow(entity); selected(entity)">
                </td>
                <td *ngIf="!enableHyperLink">{{ entity.id }}</td>
                <td *ngIf="enableHyperLink"><a [routerLink]="['', 'entity', entityDescription?.name | snakeCase, entity.id]">{{ entity.id }}</a></td>
                <td *ngFor="let field of entityDescription.getFieldsForTable(timestamps)">
                  <ng-container *ngIf="entityDescription?.name === 'PressRelease'">
                    <ng-container *ngIf="(field.type !== 'multiple-files' && field.type !== 'rich-text-editor' && field.key !== 'publication_date')">
                      <con-entity-content-by-field
                        [field]="field"
                        [entityDescription]="entityDescription"
                        [entity]="entity">
                      </con-entity-content-by-field>
                    </ng-container>
                    <ng-container *ngIf="field.key === 'publication_date'">
                      <con-date-shower *ngIf="entity?.publication_date" [showCustomTimezone]="true" [customTimezone]="entity?.publication_date_timezone" [date]="entityDescription.getAttributeByString(entity, field.key) | field:field"></con-date-shower>
                    </ng-container>
                    <ng-container *ngIf="field.type === 'rich-text-editor'">
                      <div class="excerpt-trim">
                        {{ entity.body}}
                      </div>

                    </ng-container>
                    <ng-container *ngIf="field.type === 'multiple-files'">
                      <div *ngFor="let url of getAttachmentsAsList(entity); let i = index">
                        <a target="_blank"  type="link" [conResolveLinkToFile]="url">Attachment {{i+1}}</a>
                      </div>
                    </ng-container>
                  </ng-container>
                  <!-- common columns -->
                  <ng-container *ngIf="entityDescription?.name !== 'PressRelease'">
                    <con-entity-content-by-field
                      [field]="field"
                      [entityDescription]="entityDescription"
                      [entity]="entity">
                    </con-entity-content-by-field>
                  </ng-container>
                </td>
                <td *ngIf="loaders[entity.id]">
                    <con-loader></con-loader>
                </td>
              <td  *ngIf="entityDescription?.name === 'CompanyReportFile'">
                <ng-container>
                  <button (click)="emitPreviewUrl(this.entity)" class="btn btn-sm btn-primary" >P</button>
                </ng-container>
              </td>

                <td *ngIf="actions.length && !loaders[entity.id]" class="row-action">
                    <ng-container *ngFor="let action of actions">
                        <span *ngIf="!entity.locked || action.label === 'Exclude' || action.label === 'Make Primary'">
                          <button class="btn btn-sm mr-15" [ngClass]="'btn-' + action.buttonClass" (click)="doAction(action.name, entity)"><con-icon-by-name [iconName]="action.icon"></con-icon-by-name></button></span>
                    </ng-container>

                    <ng-container *ngIf="canTranslate(entity)">
                        <span>
                            <button class="btn btn-sm btn-primary translate" (click)="openTranslation(entity)"><con-icon-by-name iconName="globe"></con-icon-by-name>Translate</button>
                        </span>
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<con-loader *ngIf="loading"></con-loader>
<con-no-data *ngIf="!loading" [count]="response?.total" [message]="noDataMessage"></con-no-data>
<div class="d-flex justify-content-center" [ngClass]="{'hide-pagination':!response?.total}">
    <ngb-pagination [collectionSize]="response?.total"
                    [(page)]="currentPage"
                    (pageChange)="changePage($event)"
                    [pageSize]="response?.per_page"
                    maxSize="10"
                    [rotate]="true"></ngb-pagination>
</div>
