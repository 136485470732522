<div class="container-fluid mb-3">
  <div>
    <div class="row">
      <div class="col-12">
        <div class="page-title mb-2 pb-3 pt-3">
          <h3 class="mb-0">Report Dates</h3>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-start sticky-header non-sticky py-2">
      <h3><span class="badge badge-dark">{{getDisplayTitle()}}</span></h3>
      <span *ngIf="entity?.company?.website" class="website-link-positioning">
            <a [href]="entity?.company?.website" class="link-fontsize" target="_blank">
              {{companyService.getStripedWebsiteString(entity?.company?.website)}}</a>
          </span>
      <div class="chip chip-inverse ml-auto">
        <con-avatar [userId]="entity.company.manager?.id" [size]="32"></con-avatar>
        {{ entity.company.manager?.first_name }} {{ entity.company.manager?.last_name }}
      </div>
    </div>
    <div>
      <div class="card mb-2">
        <div class="card-header cursor-pointer" (click)="expandCreateForm()">
          <div class="d-flex justify-content-start align-items-center">
            <con-icon-by-name iconName="paperclip" [mutations]="['fw']" class="mr-1"></con-icon-by-name> New Calendar Event
            <div class="card-header-toggle ml-auto">
              <con-icon-by-name iconName="angle-right"
                                [mutations]="getMutations(!showCreateForm)"></con-icon-by-name>
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="showCreateForm && createFormDataReady">
          <con-custom-entity-form-content entityName="CalendarEvent" [emptyAfterSave]="true" [entity]="calendarEventEntity" [preFillValues]="calendarEventEntity" [fixedValues]="prefillData"
          [initialSaveEnabled]="false" [hasFixedCalendarValues]="true" (afterSave)="updateListing()" [enableRecurringEvent]="false" [relationValues]="formRelationValues"></con-custom-entity-form-content>
        </div>
      </div>

      <div class="card mb-2">
        <div class="card-body">
          <div>
            <div class="half-width pull-left">
              <h5 class="card-title m-0">Filter Calender Events</h5>
            </div>
          </div>
          <div class="clearfix">
          </div>
          <div class="d-block mt-10">
            <div>
              <div class="row ml-0">
                <div class="col-6 pl-0">
                  <div>
                    <small>From Date</small>
                    <con-day-input [control]="fromDateControl"  [field]="fromDateField" (change)="updateFilterButtonStatus()" ></con-day-input>
                  </div>
                </div>
                <div class="col-6">
                  <div>
                    <small>To Date</small>
                    <con-day-input [control]="toDateControl"  [field]="toDateField" (change)="updateFilterButtonStatus()"></con-day-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>

          <div>
            <div *ngIf="showDateMismatchError" class="pull-left mt-10">
              <small class="mat-error"> <sup>*</sup>From report date is greater than To report date</small>
            </div>
            <button (click)="updateFilterCheck()" [disabled]="!filterButtonEnabled" class="btn btn-primary pull-right">Filter</button></div>
        </div>
      </div>

      <div class="card mb-2">
        <div>
          <con-entity-list [runUpdate]="changes$" entityName="CalendarEvent" [showSearchFields]="false" [fixedSearchParams]="eventDateRange" *ngIf="prefillData.calendar_event_type_id"></con-entity-list>
        </div>
      </div>
    </div>
  </div>
</div>
