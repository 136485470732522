import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiSettings } from 'app/settings.class';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(
    private http: HttpClient
  ) { }

  getDirektSyncList(params: HttpParams): Observable<any> {
    return this.http.get(`${ApiSettings.BASE_URL}/calendar_event/direkt_sync_status`, { params });
  }
}
